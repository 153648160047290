import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { breakpoint, getColor } from '../../shared/theme'
import SinglePostInner from '../../elements/singlePostInner'
import Quote from '../../components/quote'
import SectionTransition from '../../elements/activeSectionDefault'

const Wrapper = styled(SectionTransition)`
	padding-top: 98px;
	padding-bottom: 60px;
	margin-bottom: 60px;
	background: ${getColor('greyLight')};

	@media ${breakpoint('tablet')} {
		background: none;
		padding-top: 0;
		margin-bottom: 0;
		padding-bottom: 136px;
	}

	@media ${breakpoint('desktop')} {
		padding-bottom: 160px;
	}
`

const QuoteInner = styled.div`
	grid-column: -1 / 1;

	@media ${breakpoint('tablet')} {
		grid-column: span 10;
	}

	@media ${breakpoint('desktop')} {
		grid-column: span 9;
	}
`

const SingleBlockQuote = ({ isActive, copy, source }) => {
	if ((!copy || copy.length <= 0) && (!source || source.length <= 0)) {
		return null
	}

	return (
		<Wrapper isActive={isActive}>
			<SinglePostInner>
				<QuoteInner>
					<Quote copy={copy} source={source} />
				</QuoteInner>
			</SinglePostInner>
		</Wrapper>
	)
}

SingleBlockQuote.propTypes = {
	isActive: PropTypes.bool,
	copy: PropTypes.string,
	source: PropTypes.string,
}

SingleBlockQuote.defaultProps = {
	isActive: true,
	copy: '',
	source: '',
}

export default SingleBlockQuote
